$general_sans_400: 'General Sans Regular', sans-serif;
$general_sans_400_italic: 'General Sans Italic', sans-serif;
$general_sans_200: 'General Sans Extralight', sans-serif;
$general_sans_200_italic: 'General Sans Extralight Italic', sans-serif;
$general_sans_300: 'General Sans Light', sans-serif;
$general_sans_300_italic: 'General Sans Light Italic', sans-serif;
$general_sans_500: 'General Sans Medium', sans-serif;
$general_sans_500_italic: 'General Sans Medium Italic', sans-serif;
$general_sans_600: 'General Sans Semibold', sans-serif;
$general_sans_600_italic: 'General Sans Semibold Italic', sans-serif;
$general_sans_700: 'General Sans Bold', sans-serif;
$general_sans_700_italic: 'General Sans Bold Italic', sans-serif;


header {
    background: rgba(0,0,0,0.05);
    padding: 1em 0;

    img {
        display: inline-block;
        margin-right: 1em;
    }
}

.nav {
    font-size: 14px;

    a.nav-link {
        color: #000;
        transition: all 0.3s;

        &:hover {
            color: #000;
            text-decoration: underline;
        }
    }

}