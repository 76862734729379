$general_sans_400: 'General Sans Regular', sans-serif;
$general_sans_400_italic: 'General Sans Italic', sans-serif;
$general_sans_200: 'General Sans Extralight', sans-serif;
$general_sans_200_italic: 'General Sans Extralight Italic', sans-serif;
$general_sans_300: 'General Sans Light', sans-serif;
$general_sans_300_italic: 'General Sans Light Italic', sans-serif;
$general_sans_500: 'General Sans Medium', sans-serif;
$general_sans_500_italic: 'General Sans Medium Italic', sans-serif;
$general_sans_600: 'General Sans Semibold', sans-serif;
$general_sans_600_italic: 'General Sans Semibold Italic', sans-serif;
$general_sans_700: 'General Sans Bold', sans-serif;
$general_sans_700_italic: 'General Sans Bold Italic', sans-serif;


.tabs {
    box-shadow: 0 5px 8px rgba(0,0,0,0.05);
}

.tab {
    font-size: 14px;
    display: inline-block;
    padding: 1em 0;
    border-bottom: 3px solid transparent;
    text-decoration: none;
    font-family: $general_sans_500 !important;
    color: #000;
    transition: all 0.3s;
    margin-right: 2em;

    &:hover, &.active {
        border-bottom: 3px solid #000;
    }

}

.feedback-btn {
    float: right;
    margin-top: 0.5em;
    border-radius: 100px;
    background: linear-gradient(0deg, #001489 0%, #001489 100%), #FFF;
    display: inline-flex;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-decoration: none;
}